export default {
	color: {
		brand: '#c18992',
		brandDarker: '#A9606B',
		disabled: '#cacaca',
		dark: '#3f3f3f',
		text: '#333',
		whiteish: '#efefef',
	},
};
